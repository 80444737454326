import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/shared/layout'
import Hero from '../components/shared/hero'
import { Flex, Text } from 'theme-ui'
// import ArticlePreview from '../components/article-preview'

class RootIndex extends React.Component {
  render() {
    // const posts = get(this, 'props.data.allContentfulArtistPage.nodes')
    const [author] = get(this, 'props.data.allContentfulPerson.nodes')
    const footerText = 'All the tabs and lessons here are 100% free, as I genuinely believe that playing guitar calms a stormy mind, so this is my little gift to the human race. If you want to make a donation to support my site and YouTube channel, then click the Donate button to donate through PayPal. Thank you 🤙🏻'

    return (
      <Layout location={this.props.location}>
        <Hero
          title={author.name}
          image={author.heroImage.gatsbyImageData}
        />
        {/* <ArticlePreview posts={posts} /> */}

        <Flex sx={{ m: 'auto', p: 4, maxWidth: '50rem', flexDirection: 'column' }} >
          <Text mb={3} as="p">
            {author.shortBio.shortBio}
          </Text>
          <Text as="p">
            {footerText}
          </Text>
        </Flex>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      nodes {
        name
        shortBio {
          shortBio
        }
        title
        heroImage: image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 1180
          )
        }
      }
    }
  }
`
// allContentfulArtistPage(sort: { fields: [publishDate], order: DESC }) {
//   nodes {
//     title
//     slug
//     publishDate(formatString: "MMMM Do, YYYY")
//     tags
//     heroImage {
//       description
//       gatsbyImageData(
//         layout: FULL_WIDTH
//         placeholder: BLURRED
//         formats: WEBP
//         width: 424
//         height: 212
//       )
//     }
//     description {
//       childMarkdownRemark {
//         html
//       }
//     }
//   }
// }